import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.css']
})
export class HowWeWorkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
