import { Component, OnInit } from '@angular/core';
import { InquiryDataService } from './inquiry-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.css']
})
export class InquiryComponent implements OnInit {
  inquiry: FormGroup;

  constructor(private svc: InquiryDataService,private router:Router) {
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.loadData();

  }
  loadData() {
    this.inquiry = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*')]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      inquiry: new FormControl(null, [Validators.required, Validators.maxLength(30)]),
      description: new FormControl(null, [Validators.required, Validators.maxLength(1000)]),
    })
  }
  onSubmit() {
    this.svc.addInquiry(this.inquiry.value).subscribe(
      res => {
        alert("Thank You We will contact you soon");
        this.router.navigate(['']);
        console.log(res,"ki")
      }
    )
  }

}
