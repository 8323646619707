
<!--=================================
Contact Form -->
<section id="footer"> 
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="dark-background bg-dark border-radius p-4 p-sm-5 bg-overlay-black-90"
             style="background-image: url(images/bg/map-bg.png);">
          <div class="row">
            <div class="col-lg-7 mb-4 mb-lg-0">
              <div class="section-title">
                <h2 class="text-white">Let’s Get In Touch!</h2>
                <p class="lead text-white">Have a project or a question? We'd love to hear from you.</p>
              </div>
              <div class="contact-form contact-form-style-02">
                <form [formGroup]="inquiry" (ngSubmit)='onSubmit()'>
                  <div class="form-row">
                    <div class="col-md-6 mb-4">
                      <label class="inp">
                        <input class="form-control" type="text" placeholder="&nbsp;" formControlName="name">
                        <span class="label">Name</span>
                        <span class="border"></span>
                      </label>
                      <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('name').hasError('required') && !inquiry.get('name').pristine">Name Is require</span></h6>
                      <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('name').hasError('maxlength') && !inquiry.get('name').pristine">MaxLength is 30</span></h6>
                      <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('name').hasError('maxlength') && !inquiry.get('pattern').pristine">Only Character Allow</span></h6>
                  
                    </div>
                    <div class="col-md-6 mb-4">
                      <label class="inp">
                        <input class="form-control" type="text" placeholder="&nbsp;" formControlName="email">
                        <span class="label">Email</span>
                        <span class="border"></span>
                      </label>
                      <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('email').hasError('required') && !inquiry.get('email').pristine">Email Is require</span></h6>
                      <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('email').hasError('email') && !inquiry.get('email').pristine">Email Format is not valid</span></h6>
                  
                    </div>
                    <div class="col-12 mb-4">
                      <label class="inp">
                        <input class="form-control" type="text" placeholder="&nbsp;" formControlName="inquiry">
                        <span class="label">Your inquiry about</span>
                        <span class="border"></span>
                      </label>
                      <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('inquiry').hasError('required') && !inquiry.get('inquiry').pristine">Type Is require</span></h6>
                      <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('inquiry').hasError('maxlength') && !inquiry.get('inquiry').pristine">maxlength is 30</span></h6>
                  
                    </div>
                    <div class="col-12 mb-4">
                      <label class="inp">
                        <textarea class="form-control" placeholder="&nbsp;" rows="3" formControlName="description"></textarea>
                        <span class="label">Please describe what you need.</span>
                        <span class="border"></span>
                      </label>
                      <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('description').hasError('required') && !inquiry.get('description').pristine">Description Is require</span></h6>
           
                    </div>
                    <div class="col-12">
                      <button type="submit" class="btn btn-primary" [disabled]="!inquiry.valid"  >Send Massage<i class="fas fa-arrow-right pl-3"></i></button>
            
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-5 text-center px-xl-5">
              <div class="bg-white border-radius p-4 d-flex h-100">
                <div class="w-100 align-self-center">
                  <a routerLink=""><img class="img-fluid mb-4 mb-lg-5" src="../../assets/images/logoFinal.png"
                                              alt="logo"></a>
                  <p class="mb-4">Ahmedabad,<br>Gujarat</p>
                  <h4 class="mb-4 font-weight-bold"><a href="#">+(91) 971-486-37-12</a></h4>
                  <a class="text-dark" href="#">info@jjrsoftware.com</a>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--=================================
Contact Form -->





<!--=================================
footer-->
<footer class="footer space-ptb bg-light mt-n5">
  <div class="footer-bottom mt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <ul class="list-unstyled mb-3 mb-md-5 social-icon social-icon-lg">
            <li><a href="https://facebook.com/jjrsoftware" target="_blank"><i class="fab fa-facebook-f fa-2x"></i></a></li>
            <li><a href="https://twitter.com/JjrSoftware" target="_blank" ><i class="fab fa-twitter fa-2x"></i></a></li>
            <li><a href="https://www.linkedin.com/company/jjrsoftware" target="_blank"><i class="fab fa-linkedin-in fa-2x"></i></a></li>
            <!-- <li><a href="#"><i class="fab fa-github fa-2x"></i></a></li> -->
            <li><a href="https://www.instagram.com/jjrsoftware/" target="_blank"><i class="fab fa-instagram fa-2x"></i></a></li>
           
          </ul>
          <p class="mb-0 text-dark">©Copyright 2020 <a routerLink="">JJRSoftware</a> All Rights Reserved</p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--=================================
footer-->
