import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InquiryDataService {
  url: string = environment.url + 'sendemail/';

  constructor(private http: HttpClient) {
  }
  getAll() {
    return this.http.get(this.url);
  }
  addInquiry(item) {

    let x = new HttpHeaders().set(environment.header, environment.value);
    let body = {
      to: item.email,
      text:item.description,
      subject:item.inquiry
    }
    // let body = JSON.stringify(item);
    return this.http.post(this.url, body, { headers: x });
  }
  deleteInquiry(id) {
    let x = new HttpHeaders().set(environment.header, environment.value);
    return this.http.delete(this.url + id, { headers: x });
  }
}
