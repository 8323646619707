
<!--=================================
banner -->
<section class="banner text-center space-ptb bg-light bg-overlay-black-50" data-jarallax='{"speed": 0.4}' style="background-image: url(../../assets/images/bg/10.jpg);">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-12">
        <div class="py-6"></div>
      </div>
    </div>
  </div>
</section>
<!--=================================
Banner -->
<section class="space-pb">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-lg-9  mt-5 mt-lg-n6 position-reletive z-index-1">
        <div class="bg-primary p-5">
          <div class="section-title  ">
            <h1 class="text-white">JJR Software</h1>
            <p class="lead text-white">See some of the industries we have vast experience working in as a Software Development.</p>
          </div>
          <div class="category-style-03 text-center">
            <a href="#" class="category-item">
              <h5 class="text-white">Automotive Portal</h5>
              <div class="category-icon">
                <i class="flaticon-car fa-4x text-white"></i>
              </div>
            </a>
            <a href="#" class="category-item">
              <h5 class="text-white">E-commerce</h5>
              <div class="category-icon">
                <i class="flaticon-icon-626443 fa-4x text-white"></i>
              </div>
            </a>
            <a href="#" class="category-item">
              <h5 class="text-white">Education</h5>
              <div class="category-icon">
                <i class="flaticon-author fa-4x text-white"></i>
              </div>
            </a>
            <a href="#" class="category-item">
              <h5 class="text-white">ERP</h5>
              <div class="category-icon">
                <i class="flaticon-classified fa-4x text-white"></i>
              </div>
            </a>
<!--            <a href="#" class="category-item">-->
<!--              <h5 class="text-white">Healthcare</h5>-->
<!--              <div class="category-icon">-->
<!--                <i class="flaticon-heart fa-4x text-white"></i>-->
<!--              </div>-->
<!--            </a>-->
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="pl-0 pl-lg-5">
          <div class="section-title mt-5">
            <h2>Have a Project?</h2>
            <p>Tell us about your idea, and we’ll make it happen.</p>
          </div>
          <a routerLink="/inquiry"class="btn btn-primary-round btn-round">Get a Quote<i class="fas fa-arrow-right pl-3"></i></a>
        </div>
      </div>
    </div>

  </div>
</section>
<!--=================================
Category -->

<!--=================================
About -->
<section class="space-pt our-client">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="section-title text-center">
          <h2>Digital marketing services that help you grow.</h2>
          <p>Award-winning website design & creative digital agency services</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-3 mb-4 mb-lg-0"  id="products">
        <div class="bg-light border border-radius h-100 overflow-hidden d-flex align-items-start flex-columnbg-light border border-radius h-100 overflow-hidden d-flex align-items-start flex-columnbg-light border border-radius h-100 overflow-hidden d-flex align-items-start flex-column">
          <div class="p-4 mb-auto">
            <h4>Web Development</h4>
            <ul class="list-unstyled">
              <li class="mb-2">E-commerce strategy</li>
              <li class="mb-2">Custom design</li>
              <li class="mb-2">Front-End development</li>
              <li>Business intelligence</li>
            </ul>
          </div>
          <div class="d-flex justify-content-end w-100">
            <img class="img-fluid w-75 mr-n5" src="../../assets/images/service/01.svg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-4 mb-lg-0"  id="mobile">
        <div class="bg-light border border-radius h-100 overflow-hidden d-flex align-items-start flex-column">
          <div class="p-4 mb-auto">
            <h4>Mobile Development</h4>
            <ul class="list-unstyled">
              <li class="mb-2">iOS</li>
              <li class="mb-2">Android</li>
              <li class="mb-2">Hybrid platform</li>
              <li>User testing</li>
            </ul>
          </div>
          <div class="d-flex justify-content-end w-100">
            <img class="img-fluid w-75 mr-n5" src="../../assets/images/service/02.svg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-4 mb-md-0" id="branding">
        <div class="bg-light border border-radius h-100 overflow-hidden d-flex align-items-start flex-column">
          <div class="p-4 mb-auto">
            <h4>Branding Services</h4>
            <ul class="list-unstyled">
              <li class="mb-2">Logo design</li>
              <li class="mb-2">UI/UX design</li>
              <li class="mb-2">Visual design</li>
              <li class="mb-2">Brand identity</li>
              <li>Brand collateral</li>
            </ul>
          </div>
          <div class="d-flex justify-content-end w-100">
            <img class="img-fluid w-75 mr-n5" src="../../assets/images/service/03.svg" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3" id="marketing">
        <div class="bg-light border border-radius h-100 overflow-hidden d-flex align-items-start flex-column">
          <div class="p-4 mb-auto">
            <h4>Digital Marketing</h4>
            <ul class="list-unstyled">
              <li class="mb-2">Marketing Strategy</li>
              <li class="mb-2">Paid Media</li>
              <li class="mb-2">Influencer Marketing</li>
              <li class="mb-2">Digital PR</li>
              <li>Content Marketing</li>
            </ul>
          </div>
          <div class="d-flex justify-content-end w-100">
            <img class="img-fluid w-75 mr-n5" src="../../assets/images/service/04.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="space-ptb">
  <div class="container">
    <div class="row justify-content-center mb-4 mb-md-5">
      <div class="col-lg-6 pb-4 pb-lg-0">
        <div class="section-title mb-3">
          <h2>We enable constant enterprise transformation at speed and scale.</h2>
        </div>
        <p>We are a creative company, who works with passion and love. We provide the best services you need. We help
          you to get better, We take pride in helping our clients deliver marvelous results when it comes to their
          projects. From data to performance, we’ve got you covered.</p>
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
               style="width:95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar-title">Project success percentage rate</div>
            <span class="progress-bar-number">95%</span>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center bg-light p-3 border-radius">
          <ul class="list-unstyled list-inline mr-4 mb-0">
            <li class="list-inline-item mr-1 font-md  align-items-center"><b class="font-xl">5</b> <i
              class="fas fa-star text-warning"></i></li>
          </ul>
          <h6 class="mb-0">Rated 4.80 out of 5 based on over <span class="text-primary"> 100+ reviews</span></h6>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-sm-6">
            <img class="img-fluid border-radius mb-4 mt-4" src="../../assets/images/about/05.jpg" alt="">
            <img class="img-fluid border-radius mb-4 mb-sm-0" src="../../assets/images/about/06.jpg" alt="">
          </div>
          <div class="col-sm-6">
            <img class="img-fluid border-radius mb-4" src="../../assets/images/about/14.jpg" alt="">
            <div class="counter counter-03">
              <div class="counter-content">
                <span class="timer" data-to="235" data-speed="10000">05</span>
                <label>Finished Projects</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--=================================
Feature info -->

<!--=================================
Category -->
<section class="space-ptb bg-dark" >
  <div class="container">
    <div class="row dark-background align-items-center justify-content-center">
      <div class="col-lg-6">
        <h2 class="text-white mb-3 mb-lg-0">Four reasons why you should choose our service</h2>
      </div>
      <div class="col-lg-6 text-lg-right">
        <a routerLink="/inquiry" class="btn btn-primary-round btn-round text-white w-space">Let’s Get Started<i
          class="fas fa-arrow-right pl-3"></i></a>
      </div>
    </div>
    <div class="row dark-background mt-4 mt-md-5">
      <div class="col-lg-3 col-md-6 mb-5 mb-lg-0" id='services'>
        <div class="feature-info feature-info-style-06">
          <div class="feature-info-img">
            <img class="img-fluid" src="../../assets/images/feature-info/01.jpg" alt="">
          </div>
          <div class="feature-info-number mb-0">
            <span>01</span>
            <h5 class="mb-0 ml-4 feature-info-title">We know your business already</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5 mb-lg-0">
        <div class="feature-info feature-info-style-06">
          <div class="feature-info-img">
            <img class="img-fluid" src="../../assets/images/feature-info/02.jpg" alt="">
          </div>
          <div class="feature-info-number mb-0">
            <span>02</span>
            <h5 class="mb-0 ml-4 feature-info-title">Building context – not just code</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5 mb-md-0">
        <div class="feature-info feature-info-style-06">
          <div class="feature-info-img">
            <img class="img-fluid" src="../../assets/images/feature-info/03.jpg" alt="">
          </div>
          <div class="feature-info-number mb-0">
            <span>03</span>
            <h5 class="mb-0 ml-4 feature-info-title">We are responsive and flexible</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="feature-info feature-info-style-06">
          <div class="feature-info-img">
            <img class="img-fluid" src="../../assets/images/feature-info/04.jpg" alt="">
          </div>
          <div class="feature-info-number mb-0">
            <span>04</span>
            <h5 class="mb-0 ml-4 feature-info-title">1 years experience – and counting</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--=================================
Category -->

<!--=================================
Category -->

<!--=================================
video -->
<!-- <section class="space-pt bg-overlay-gradient-x-light-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="section-title text-center">
          <h2>Know more about who we are what we do.</h2>
          <p>Focus is having the unwavering attention to complete what you set out to do. There are a million distractions in every facet of our lives. Telephones and e-mail, clients and managers,</p>
        </div>
        <div class=" h-500 border-radius d-flex text-center pl-3 pb-3 bg-overlay-black-10" data-jarallax='{"speed": 0.9}' data-jarallax-video="https://www.youtube.com/embed/9No-FiEInLA">
            <a class="icon-btn icon-btn-lg video-btn btn-animation popup-youtube" href="https://www.youtube.com/watch?v=LgvseYYhqU0"><i class="fas fa-play fa-1x"></i></a>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--=================================
video -->


<!--=================================
Category -->

<!--=================================
Action Box -->
<section class="space-pb dark-background">
  <div class="container">
    <div class="bg-dark text-center rounded py-5 px-3">
      <h2 class="text-white">Tell us about your idea, and we’ll make it happen.</h2>
      <h6 class="text-white">Have a brand problem that needs to be solved? We’d love to hear about it!</h6>
      <a routerLink="/inquiry" class="btn btn-primary-round btn-round mx-0 mx-md-3 text-white">Let’s Get Started<i
        class="fas fa-arrow-right pl-3"></i></a>
    </div>
  </div>
</section>
<!--=================================
Action Box -->

<!--=================================
Feature -->
<section class="space-pb" id="smartstratrgy">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="section-title text-center">
          <h2>Smart strategy & excellent performance</h2>
          <p>Process that guarantees high productivity and profitability for your solution.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-4 mb-md-0">
        <div class="feature-info feature-info-style-05 text-center">
          <div class="feature-info-icon">
            <i class="flaticon-idea-1"></i>
          </div>
          <div class="feature-info-content">
            <h5 class="mb-3 feature-info-title">Data Analysis </h5>
            <p class="mb-0 px-lg-5">We conduct thorough data collection and analysis of your institution’s current
              strengths and differentiators.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4 mb-md-0">
        <div class="feature-info feature-info-style-05 text-center">
          <div class="feature-info-icon">
            <i class="flaticon-diamond"></i>
          </div>
          <div class="feature-info-content">
            <h5 class="mb-3 feature-info-title">Making a plan</h5>
            <p class="mb-0 px-lg-5">We will work together with you to develop a plan that is actionable, feasible and
              tailored to your needs.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4 mb-md-0">
        <div class="feature-info feature-info-style-05 text-center">
          <div class="feature-info-icon">
            <i class="flaticon-rocket"></i>
          </div>
          <div class="feature-info-content">
            <h5 class="mb-3 feature-info-title">Deliver Result</h5>
            <p class="mb-0 px-lg-5">Our role in this step is to provide expertise where needed, to augment select
              initiatives as desired.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 mt-md-5">
      <div class="col-12 d-md-flex justify-content-center align-items-center text-center">
        <p class="mb-3 mb-md-0 mx-0 mx-md-3">Our Managed IT services will help you succeed.</p>
        <a routerLink="/inquiry" class="btn btn-primary-round btn-round mx-0 mx-md-3">Let’s Get Started<i
          class="fas fa-arrow-right pl-3"></i></a>
      </div>
    </div>
  </div>
</section>
<!--=================================
Feature -->

<!--=================================
Testimonial -->
<section class="space-ptb bg-overlay-black-90 bg-holder" style="background-image: url(assets/images/bg/01.jpg);">
  <div class="container">
    <div class="row justify-content-center row-background">
      <div class="col-sm-12 text-center">
        <div class="owl-carousel testimonial" data-nav-arrow="true" data-nav-dots="false" data-items="2"
             data-lg-items="2" data-md-items="2" data-sm-items="2" data-space="0" data-autoheight="true">
          <div class="item">
            <div class="testimonial-item">
              <div class="testimonial-avatar">
                <img class="img-fluid rounded-circle" src="../../assets/images/harsh.jpeg" alt="">
              </div>
              <div class="testimonial-content">
                <p>We don't take ourselves too seriously, but seriously enough to ensure we're creating the best product
                  and experience for our customers.</p>
              </div>
              <div class="testimonial-author">
                <div class="testimonial-name">
                  <h6 class="mb-1 text-white">HarsH Thakkar</h6>
                  <span>Software Developer</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-item">
              <div class="testimonial-avatar">
                <img class="img-fluid" src="../../assets/images/kirpal.jpg" alt="">
              </div>
              <div class="testimonial-content">
                <p>"Software developers are the architects of the digital world, weaving code into the fabric of innovation .</p>
              </div>
              <div class="testimonial-author">
                <div class="testimonial-name">
                  <h6 class="mb-1 text-white">Kirpal Solanki</h6>
                  <span>Web Developer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--=================================
Testimonial -->

<!--=================================
Blog -->
<!--=================================
BLog -->
<!--=================================
Back To Top-->

<div id="back-to-top" class="back-to-top"(click)="ontop()">up</div>
<!--=================================
Back To Top-->
