import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { CareersComponent } from './careers/careers.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { MissionVisionComponent } from './mission-vision/mission-vision.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'inquiry', component: InquiryComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'how-we-work', component: HowWeWorkComponent },
  { path: 'mission-vision', component: MissionVisionComponent },
  { path: 'contactus', component: ContactComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
