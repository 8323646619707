<header class="header default">
  <div class="topbar">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-block d-md-flex align-items-center text-center">
            <div class="mr-4 d-inline-block py-1">
              <a href="#"><i class="far fa-envelope mr-2 fa-flip-horizontal text-primary"></i>info@jjrsoftware.com</a>
            </div>
            <div cla ss="mr-auto d-inline-block py-1">
              <a href="tel:9714863712"><i class="fas fa-map-marker-alt text-primary mr-2"></i>Ahmedabad,Gujarat</a>
            </div>
            <div class="d-inline-block py-1">
              <ul class="list-unstyled">
                <li><a routerLink="/about">About Us</a></li>
                <li><a routerLink="/mission-vision">Mission & Vision</a></li>
                <li><a routerLink="/how-we-work">How we work</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar bg-white pt-2 pb-2 navbar-static-top navbar-expand-lg">
    <div class="container-fluid">
      <button type="button" class="navbar-toggler" data-toggle="collapse" data-target=".navbar-collapse"><i
          class="fas fa-align-left"></i></button>
      <a class="navbar-brand" routerLink="">
        <img class="logo" src="../../assets/images/logoFinal.png" alt="logo">
      </a>
      <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav">
          <li class="nav-item dropdown active">
            <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">Home</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class=""><a class="dropdown-item" href="#products">Products<i class="fas fa-arrow-right"
                    close></i></a></li>
              <li><a class="dropdown-item" href="#services">Our Service<i class="fas fa-arrow-right"></i></a></li>
              <li><a class="dropdown-item" href="#smartstratrgy">Smart Strayrgy<i class="fas fa-arrow-right"></i></a>
              </li>
              <li><a class="dropdown-item" href="#footer">Get in Touch<i class="fas fa-arrow-right"></i></a></li>
            </ul>
          </li>
          <li class="dropdown nav-item">
            <a class="nav-link" data-toggle="dropdown">Company</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" routerLink='/about'>About Us<i class="fas fa-arrow-right"></i></a></li>
              <li><a class="dropdown-item" routerLink='/careers'>Careers<i class="fas fa-arrow-right"></i></a></li>
              <li><a class="dropdown-item" routerLink="how-we-work">How We Work<i class="fas fa-arrow-right"></i></a>
              </li>
              <li><a class="dropdown-item" routerLink="mission-vision">Mission Vision<i
                    class="fas fa-arrow-right"></i></a></li>
            </ul>
          </li>
          <li class="dropdown nav-item">
            <a class="nav-link" data-toggle="dropdown">Industries</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#products">Websites<i class="fas fa-arrow-right"></i></a></li>
              <li><a class="dropdown-item" href="#mobile">Android/IOS<i class="fas fa-arrow-right"></i></a></li>
              <li><a class="dropdown-item" href="#marketing">Marketing Service<i class="fas fa-arrow-right"></i></a>
              </li>
              <li><a class="dropdown-item" href="#branding">Branding Service<i class="fas fa-arrow-right"></i></a></li>
            </ul>
          </li>
          <!-- <li class="dropdown nav-item">
            <a href="properties.html" class="nav-link" data-toggle="dropdown">Case Studies</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="case-study.html">Case Studies<i class="fas fa-arrow-right"></i></a></li>
              <li><a class="dropdown-item" href="case-study-single.html">Case Study Single<i class="fas fa-arrow-right"></i></a></li>
              <li><a class="dropdown-item" href="case-study-single-02.html">Case Study Single 02<i class="fas fa-arrow-right"></i></a></li>
            </ul>
          </li>
          <li class="dropdown nav-item mega-menu">
            <a href="#" class="nav-link" data-toggle="dropdown">Mega Menu</a>
            <ul class="dropdown-menu megamenu">
              <li>
                <div class="row">
                  <div class="col-sm-6 col-lg-3">
                    <h6 class="mb-3 nav-title">Menu Title </h6>
                    <ul class="list-unstyled mt-lg-3">
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                    </ul>
                  </div>
                  <div class="col-sm-6 col-lg-3">
                    <h6 class="mb-3 nav-title">Menu Title</h6>
                    <ul class="list-unstyled mt-lg-3">
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                    </ul>
                  </div>
                  <div class="col-sm-6 col-lg-3">
                    <h6 class="mb-3 nav-title">Menu Title</h6>
                    <ul class="list-unstyled mt-lg-3">
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                    </ul>
                  </div>
                  <div class="col-sm-6 col-lg-3">
                    <h6 class="mb-3 nav-title">Menu Title</h6>
                    <ul class="list-unstyled mt-lg-3">
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                      <li><a href="#">Menu item </a></li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
      <div class="d-none d-sm-flex ml-auto mr-5 mr-lg-0 pr-4 pr-lg-0">
        <ul class="nav ml-1 ml-lg-2 align-self-center">
          <li class="contact-number nav-item pr-4 ">
            <a class="font-weight-bold" href="#"><i class="fab fa-whatsapp pr-2"></i>+(91) 97-148-637-12</a>
          </li>
          <!-- <li class="header-search nav-item">
            <div class="search">
              <a class="search-btn not_click" href="javascript:void(0);"></a>
              <div class="search-box not-click">
                <form action="https://themes.potenzaglobalsolutions.com/html/hi-soft/search.html" method="get">
                  <input type="text" class="not-click form-control" name="search" placeholder="Search..">
                  <button class="search-button" type="submit"> <i class="fa fa-search not-click"></i></button>
                </form>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</header>
<!--=================================
header -->