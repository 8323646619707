<!--=================================
   Contat Form -->
<section class="space-ptb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title text-center">
          <h1> Contact us</h1>
          <p>Drop us a line! We’ll set up a time to chat over the phone or in-person to know more about your business
            needs. All primary conferences are free of charge.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-lg-around position-relative pt-5">
      <div class="col-lg-4 col-md-5 mb-4">
        <div class="is-sticky">
          <h4 class="mb-4">Let’s talk about what you want to accomplish and how we can make it happen.</h4>
          <h5 class="text-light">This is the beginning of creating the life that you want to live.</h5>
        </div>
      </div>
      <div class="col-lg-7 col-md-7 pr-lg-5">
        <div class="p-4 p-md-5 bg-white shadow">
          <h3>Need assistance? please fill the form</h3>
          <form class="mt-4" [formGroup]="inquiry" (ngSubmit)='onSubmit()'>
            <div class="form-group mb-3">
              <input type="text" class="form-control" id="exampleInputName" placeholder="Name" formControlName="name">
                <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('name').hasError('required') && !inquiry.get('name').pristine">Name Is require</span></h6>
                <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('name').hasError('maxlength') && !inquiry.get('name').pristine">MaxLength is 30</span></h6>
                <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('name').hasError('maxlength') && !inquiry.get('pattern').pristine">Only Character Allow</span></h6>
            </div>
            
            <div class="form-group mb-3">
              <input type="text" class="form-control" id="exampleInputEmail" placeholder="Email Address" formControlName="email">
              <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('email').hasError('required') && !inquiry.get('email').pristine">Email Is require</span></h6>
                <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('email').hasError('email') && !inquiry.get('email').pristine">Email Format is not valid</span></h6>
               
            </div>
            <div class="form-group mb-3">
              <input type="text" class="form-control" id="exampleInputEnquiry" placeholder="Enquiry Type" formControlName="inquiry">
              <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('inquiry').hasError('required') && !inquiry.get('inquiry').pristine">Type Is require</span></h6>
                <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('inquiry').hasError('maxlength') && !inquiry.get('inquiry').pristine">maxlength is 30</span></h6>
               
            </div>
            <div class="form-group mb-4">
              <textarea class="form-control" id="exampleInputEnquiry-Description" placeholder="Enquiry Description" formControlName="description"
                        rows="5"></textarea>
                     
            </div>
            <h6><span class="badge badge-pill badge-danger" *ngIf="inquiry.get('description').hasError('required') && !inquiry.get('description').pristine">Description Is require</span></h6>
           
            
            <div class="form-group mb-0">
              <button type="submit" class="btn btn-primary" [disabled]="!inquiry.valid"  >Send Massage<i class="fas fa-arrow-right pl-3"></i></button>
            </div>
          </form>
        </div>
      </div>
      <div class="contact-bg-logo">
        <i class="fas fa-comment"></i>
      </div>
    </div>
  </div>
</section>
<!--=================================
Contat Form  -->
