<section class="header-inner header-inner-menu bg-overlay-black-30" style="background-image: url('../../assets/images/header-inner/09.jpg');">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-8">
          <div class="header-inner-title text-center">
            <h1 class="text-white font-weight-normal">How we work</h1>
            <p class="text-white mb-0">Make a list of your achievements toward your long-term goal and remind yourself that intentions don’t count, only action’s.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="header-inner-nav">
      <div class="container">
        <div class="row">
          
        </div>
      </div>
    </div>
  </section>
  <!--=================================
  Header Inner -->
    
  <!--=================================
  About -->
  <section class="space-ptb">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="section-title text-center">
            <h2>Making a decision to do something this is the first step. We all know that nothing moves until someone makes a decision.</h2>
            <p class="mb-4">Franklin’s extraordinary success in life and politics can be attributed to his perseverance to overcome his personal liabilities, and his desire to constantly become better. Next time you really want to achieve something, take time to focus on your own personal journal.</p>
            <p>I truly believe Augustine’s words are true and if you look at history you know it is true. There are many people in the world with amazing talents who realize only a small percentage of their potential. We all know people who live this truth.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 mb-4 mb-sm-0">
          <img class="img-fluid border-radius" src="../../assets/images/about/10.jpg" alt="">
        </div>
        <div class="col-sm-6">
          <img class="img-fluid border-radius" src="../../assets/images/about/11.jpg" alt="">
        </div>
      </div>
    </div>
  </section>
  <!--=================================
  About -->

  <!--=================================
  History -->
  <section class="space-pb">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="section-title is-sticky">
            <h2>Smart strategy & excellent performance</h2>
            <p class="mb-4 mb-md-5">Process that guarantees high productivity and profitability for your solution.</p>
            <a routerLink="/inquiry" class="btn btn-light-round btn-round">Let's Start Project<i class="fas fa-arrow-right pl-3"></i></a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="feature-info feature-info-style-08">
            <div class="feature-info-inner">
              <div class="feature-info-item">
                <div class="feature-info-number"><span>01</span></div>
                <div class="feature-info-content">
                  <h5 class="mb-3 feature-info-title">Analysis</h5>
                  <p class="mb-0">Then with that thing in mind, follow these simple steps. Step One: Get yourself nice and relaxed and settled. Concentrate on your breathing, engage in the moment and spend some time being still, quiet and drift inside of your own mind. Hypnotize yourself here. </p>
                </div>
              </div>
              <div class="feature-info-item">
                <div class="feature-info-number"><span>02</span></div>
                <div class="feature-info-content">
                  <h5 class="mb-3 feature-info-title">Design</h5>
                  <p class="mb-0">That is your imagination doing that. You know the sound that your feet make when you walk across gravel don’t you? You can imagine it, but you are not hearing it in your ears, are you? Just imagine these things as best as you can.</p>
                </div>
              </div>
              <div class="feature-info-item">
                <div class="feature-info-number"><span>03</span></div>
                <div class="feature-info-content">
                  <h5 class="mb-3 feature-info-title">Development</h5>
                  <p class="mb-0">Become aware of the temperature, the sights, the sounds and enjoy walking along the path of your life. Make it sensory rich and get comfortable with the idea. Imagine the feeling of your feet walking along the path and the sound they make.</p>
                </div>
              </div>
              <div class="feature-info-item">
                <div class="feature-info-number"><span>04</span></div>
                <div class="feature-info-content">
                  <h5 class="mb-3 feature-info-title">Testing</h5>
                  <p class="mb-0">Engage with the idea of really being there. Step Three: Imagine that a few more steps ahead there is a place where the path splits, where is goes off to the left and off to the right. Pause here for a few moments and have a think.</p>
                </div>
              </div>
              <div class="feature-info-item">
                <div class="feature-info-number"><span>05</span></div>
                <div class="feature-info-content">
                  <h5 class="mb-3 feature-info-title">Go-Live</h5>
                  <p class="mb-0">Think about that as you stand at this place where the path splits. You want to make a decision and commit to one of these paths. Before you make that decision, we are going to see what each path holds for your future.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--=================================
  History -->
