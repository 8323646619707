<section class="header-inner header-hight header-inner-menu bg-overlay-black-50" style="background-image: url('../../assets/images/logoFinal.png');margin: 5%;">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-8">
          <div class="header-inner-title text-center">
            <!-- <h1 class="text-white font-weight-normal">About Hi-soft</h1> -->
            <!-- <p class="text-white mb-0">Our Expertise. Know more about what we do</p> -->
          </div>
        </div>
      </div>
    </div>
    
  </section>
  <!--=================================
  Header Inne -->

  <!--=================================
  History -->
  <section class="space-ptb">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="section-title text-center">
            <h2>Established 2018 in JJR Software has been offering world-class information technology.</h2>
            <p class="px-xl-5">Positive pleasure-oriented goals are much more powerful motivators than negative fear-based ones. Although each is successful separately, the right combination of both is the most powerful motivational force known to humankind.</p>
          </div>
        </div>
      </div>
      
    </div>
  </section>
  
  <!--=================================
  History -->

 
  <!--=================================
  portfolio -->

  <!--=================================
  Counter -->
  <section class="py-4 bg-transparant border">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="counter counter-02">
            <div class="counter-icon align-self-center">
              <i class="flaticon-emoji"></i>
            </div>
            <div class="counter-content align-self-center">
              <span class="timer" data-to="1790" data-speed="10000">55</span>
              <label>Happy Clients</label>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="counter counter-02">
            <div class="counter-icon">
              <i class="flaticon-trophy"></i>
            </div>
            <div class="counter-content">
              <span class="timer" data-to="245" data-speed="10000">70</span>
              <label>Skilled Experts</label>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="counter counter-02">
            <div class="counter-icon">
              <i class="flaticon-strong"></i>
            </div>
            <div class="counter-content">
              <span class="timer" data-to="491" data-speed="10000">50</span>
              <label>Finished Projects</label>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="counter counter-02">
            <div class="counter-icon">
              <i class="flaticon-icon-149196"></i>
            </div>
            <div class="counter-content">
              <span class="timer" data-to="1090" data-speed="10000">109</span>
              <label>Media Posts</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--=================================
  Counter -->
 <!--=================================
  Action Box -->
  <section class="space-pb dark-background">
    <div class="container">
      <div class="bg-dark text-center rounded py-5 px-3">
        <h2 class="text-white">Tell us about your idea, and we’ll make it happen.</h2>
        <h6 class="text-white">Have a brand problem that needs to be solved? We’d love to hear about it!</h6>
        <a routerLink="/inquiry" class="btn btn-primary-round btn-round mx-0 mx-md-3 text-white">Let’s Get Started<i class="fas fa-arrow-right pl-3"></i></a>
      </div>
    </div>
  </section>
